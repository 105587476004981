import React from "react";
import SlotCounter from 'react-slot-counter';

export const About = (props) => {
  const currentYear = new Date().getFullYear();

  return (
    <div id="about">
      <div className="overlay">
        <div className="container">
          <div className="col-md-12 section-title"> <h2 >About Us</h2> </div>
          <div className="row aboutTop">
            <div className="col-md-6 col-xs-12  aboutImgWrapper">
              {" "}
              <img src={props.data ? props.data.image : "loading..."} className="img-responsive" alt="" />{" "}              
              <video autoPlay muted loop controls playsInline type="video/mp4" src={props.data ? props.data.video : "loading..."} />
            </div>
            <div className="col-md-6 col-xs-12 ">
              <div className="about-text">
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
              </div>
            </div>
          </div>
          <div className="row aboutBottom">
            <div className="col-md-6 col-xs-12 counterWrapper">
              <div className="counter">
                <div className="counterNumber">
                  <h1><SlotCounter
                    value={props.data ? props.data.yearOfEstablishment : "loading..."}
                    duration={1}
                    animateOnVisible={{ triggerOnce: false }}
                    direction="bottom-up"
                    autoAnimationStart={true}
                  // startValue={9111}
                  // sequentialAnimationMode
                  />‎</h1>
                  <div className="counterTitle">
                    <h3>Established<br />Since.</h3>
                  </div>
                </div>
              </div>
              <div className="counter">
                <div className="counterNumber">
                  <h1><SlotCounter
                    value={props.data ? currentYear - props.data.yearOfEstablishment : "loading..."}
                    duration={1}
                    animateOnVisible={{ triggerOnce: false }}
                    direction="bottom-up"
                    autoAnimationStart={true}
                  // startValue={9111}
                  // sequentialAnimationMode
                  />+</h1>
                  <div className="counterTitle">
                    <h3>Years of<br />Experience.</h3>
                  </div>
                </div>
              </div>
              <div className="counter">
                <div className="counterNumber">
                  <h1><SlotCounter
                    value={props.data ? props.data.project : "loading..."}
                    duration={1}
                    animateOnVisible={{ triggerOnce: false }}
                    direction="bottom-up"
                    autoAnimationStart={true}
                  // startValue={9111}
                  // sequentialAnimationMode
                  />+</h1>
                  <div className="counterTitle">
                    <h3>Completed<br />Projects.</h3>
                  </div>
                </div>
              </div>
              <div className="counter">
                <div className="counterNumber">
                  <h1><SlotCounter
                    value={props.data ? props.data.workforce : "loading..."}
                    duration={1}
                    animateOnVisible={{ triggerOnce: false }}
                    direction="bottom-up"
                    autoAnimationStart={true}
                  // startValue={9111}
                  // sequentialAnimationMode
                  />+</h1>
                  <div className="counterTitle">
                    <h3>Skilled<br />Workforce.</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xs-12 aboutWhy">
              <h3>{props.data ? props.data.reasons : "loading..."}</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul> {props.data ? props.data.Why.map((d, i) => (<li key={`${d}-${i}`}>{d}</li>)) : "loading"} </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul> {props.data ? props.data.Why2.map((d, i) => (<li key={`${d}-${i}`}> {d}</li>)) : "loading"} </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};