import React from "react";
import { Link } from "react-router-dom";

export const Services = (props) => {

  let serviceLink;
  props.link ? serviceLink = props.link : serviceLink = "loading";

  if (props.source) {
    if (props.source === "home") {
      return (
        <div id="services" className="text-center">
          <div className="overlay">
            <div className="container">
              <div className="section-title">
                <h2>Our Services</h2>
                <p> We Provide {props.data ? props.data[0].serviceList.length + props.data[1].serviceList.length : "Several"} Types of Services in {props.data ? props.data.length : "Several"} Different Sectors. </p>
              </div>
              {props.data ? props.data.map((d, i) => (
                <div key={`${d.link}-${i}`} className="serviceCardWrapper">
                  <div>
                    <h3 className="serviceCardWrapperName">{i + 1}. {d.title} Services</h3>
                  </div> {" "}
                  <div className="row">
                    {d.serviceList.map((s) => (
                      <div key={`${s.name}-${i}`} className="col-sm-6 col-md-4 serviceCard"> {" "}
                        <div className="service">
                          <i className={s.icon}></i>
                          <div className="service-desc">
                            <h3>{s.name}</h3>
                            <p>{s.text}</p>
                          </div>
                          <Link className="serviceBtn" to={d.link} key={`${s.name}-${i + 1}`}>Know More</Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )) : "loading"}
            </div>
          </div>
        </div>
      );
    }
    else if (props.source === "ServicePage") {
      return (
        <div id="services" className="text-center">
          <div className="overlay">
            {props.data ? props.data.map((d, i) => {
              if (d.link === serviceLink) {

                return (
                  <div key={`${d.name}-${i}`} className="container">
                    <div className="section-title">
                      <h2>Our {d.title} Services</h2>
                      <p> We Provide {d.serviceList ? d.serviceList.length : "several"} Types of Services by {d.Companies ? d.Companies.CompanyList.length : "several"} Companies in {d.title} Sector. </p>
                    </div> {" "}
                    <div className="row serviceCardWrapper">
                      {d.serviceList.map((s) => (
                        <div key={`${s.name}-${i}`} className="col-sm-6 col-md-4 serviceCard">
                          {" "}
                          <div className="service">
                            <i className={s.icon}></i>
                            <div className="service-desc">
                              <h3>{s.name}</h3>
                              <p>{s.text}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              }
            }) : "loading"}
          </div>
        </div>
      )
    }
    else if (props.source === "individualCompany") {
      return (
        <div id="services" className="text-center">
          <div className="overlay">
            <div className="container">
              <div className="section-title">
                <h2>Our Services</h2>
                <p> We Provide {props.data ? props.data.serviceList.length : "Several"} Types of Services in {props.data ? props.data.title : "Several"} Sector. </p>
              </div>
              <div className="row serviceCardWrapper">
                {props.data ? props.data.serviceList.map((d, i) => (
                  <div key={`${d.link}-${i}`} className="col-sm-6 col-md-4 serviceCard"> {" "}
                    <div className="service">
                      <i className={d.icon}></i>
                      <div className="service-desc">
                        <h3>{d.name}</h3>
                        <p>{d.text}</p>
                      </div>
                    </div>
                  </div>
                )) : "loading"}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
};