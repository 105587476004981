import { useState } from "react";
import React from "react";

export const Footer = (props) => {

  const currentYear = new Date().getFullYear();

  return (
    <div>
      <div id="footer">
        <div className="container text-center">
          <p>
            Copyright &copy; 1999-{currentYear} RAFADA Group. All rights reserved.
            <span title={props.data ? props.data.designConcept : ""}> Design, Development and Technical Support by{" "}
              <a href={props.data ? props.data.developerLink : "/"}
                target="_blank"
                rel="noreferrer">
                {props.data ? props.data.developer : "loading"}
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};