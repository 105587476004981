import React from "react";

export const Management = (props) => {
  return (
    <div id="management" className="text-center">
      <div className="overlay">
        <div className="container">
          <div className="col-md-12 section-title">
            <h2>{props.data ? props.data.title : "Loading"}</h2>
            <p>{props.data ? props.data.subTitle : "Loading"}</p>
          </div>
          <div id="row">
            {props.data ? props.data.members.map((d, i) => (
              <div key={`${d.name}-${i}`} className={"col-md-3 col-sm-6 management " + d.class}>
                <div className="managementCard">
                  {" "}
                  <div className="management-img">
                    <img src={d.img} alt="..." />
                  </div>
                  <div className="caption">
                    <h4>{d.name}</h4>
                    <p>{d.designation}</p>
                  </div>
                </div>
              </div>
            )) : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};