import React from "react";
import { Link } from 'react-router-dom'

export const Navigation = (props) => {

  if (props.source === "home") {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <Link className="navbar-brand" to="/"> Rafada Group </Link>{" "}
          </div>
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1" >
            <ul className="nav navbar-nav navbar-right">
              <li><a href="#about" className="page-scroll">About</a></li>
              <li className="dropdown dropdown-toggle page-scroll">
                <a href="/" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Services<span className="caret"></span></a>
                <ul className="dropdown-menu nav navbar-nav">
                  {props.data ? props.data.map((d, i) => (<li key={`${d.title}-${i}`}><Link to={`/${d.link}`}>{d.title}</Link></li>)) : "Loading..."}
                </ul>
              </li>
              <li><a href="#clients" className="page-scroll">Clients</a></li>
              <li><a href="#management" className="page-scroll">Management</a></li>
              <li><a href="#contact" className="page-scroll">Contact</a></li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }

  else if (props.source === "servicePage") {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <Link className="navbar-brand" to="/"> Rafada Group </Link>{" "}
          </div>
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1" >
            <ul className="nav navbar-nav navbar-right">
              <li><a href="/" className="page-scroll">Home</a></li>
              <li className="dropdown dropdown-toggle page-scroll">
                <a href="/" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Services<span className="caret"></span></a>
                <ul className="dropdown-menu nav navbar-nav">
                  {props.data ? props.data.map((d, i) => (<li key={`${d.title}-${i}`}><Link to={`/${d.link}`}>{d.title}</Link></li>)) : "Loading..."}
                </ul>
              </li>
              <li><a href="#services" className="page-scroll">{props.navItem ? props.navItem + " Services" : "Service"}</a></li>
              <li><a href="#companies" className="page-scroll">Companies</a></li>
              <li><a href="#contact" className="page-scroll">Contact</a></li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }

  else if (props.source === "individualCompany") {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <Link className="navbar-brand" to="/"> Rafada Group </Link>{" "}
          </div>
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1" >
            <ul className="nav navbar-nav navbar-right">
              <li><a href="/" className="page-scroll">Home</a></li>
              <li><a href="#about" className="page-scroll">About</a></li>
              <li className="dropdown dropdown-toggle page-scroll">
                <a href="/" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Services<span className="caret"></span></a>
                <ul className="dropdown-menu nav navbar-nav">
                  {props.data ? props.data.map((d, i) => (<li key={`${d.title}-${i}`}><Link to={`/${d.link}`}>{d.title}</Link></li>)) : "Loading..."}
                </ul>
              </li>
              <li><a href="#services" className="page-scroll">Our Services</a></li>
              <li><a href="#contact" className="page-scroll">Contact</a></li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
};