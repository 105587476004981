import React from "react";
import { Link } from "react-router-dom";

export const Companies = (props) => {
  return (
    <div id="companies" className="text-center">
      <div className="overlay">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Our {props.data ? props.data.title : "Service"}</h2>
            <p>We Have a Total of {props.data ? props.data.CompanyList.length : "Several"} {props.data ? props.data.title : "Service"} Around the UAE.</p>
          </div>
          <div className="row center-block companyList">
            {props.data ? props.data.CompanyList.map((d, i) => (
              <div key={`${d.title}-${i}`} className="col-sm-6 col-md-4 companyCard">
                {" "}
                <div className="company">
                  <i className={"companyIcon " + d.icon}></i>
                  <h3 className="companyName">{d.name}</h3>
                  <p className="companyDescription">{d.text}</p>
                  <Link className="companyBtn" key={`${d.name}-${i}`} to={`/${d.link}`} >Know More</Link>
                </div>
              </div>
            )) : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};