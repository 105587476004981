import React from "react";
import Tilt from 'react-parallax-tilt';

export const Header = (props) => {

  return (
    <header id="header">
      <div className="intro"
        style={{ backgroundImage: `url(${(props.data ? props.data.image : "loading...")})` }}
      >
        <video autoPlay muted loop playsInline type="video/mp4" src={props.data ? props.data.video : "loading..."} />
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-12 intro-text">
                <div className="headerName">
                  <Tilt
                    className="background-stripes parallax-effect-glare-scale"
                    perspective={1000}
                    tiltEnable={true}
                    glareEnable={true}
                    glareMaxOpacity={0.5}
                    glareColor="#ffffffad"
                    glarePosition="all"
                    glareBorderRadius="10px"
                    scale={1.01}
                  >
                    <h1>{props.data ? props.data.name : "Loading"}</h1>
                    <h3>{props.data ? props.data.slogan : "Loading"}</h3>
                    <h4>{props.data ? props.data.title : "Loading"} </h4>
                  </Tilt>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};