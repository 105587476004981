//Essentials
import React, { useEffect } from 'react'
//Components
import { Header } from '../components/header';
import { Services } from '../components/services';
import { Contact } from '../components/contact';
import { Companies } from '../components/companies';
import { Navigation } from '../components/navigation';

export const ServicePage = (props) => {
  let serviceLink;
  props.link ? serviceLink = props.link : serviceLink = "loading";

  return (
    <>
      {props.data ?
        props.data.map((d, i) => {
          if (d.link === serviceLink) {
            return (
              <div key={`${d.title}-${i}`} className="servicePage">
                {" "}
                <Navigation data={props.data} source={"servicePage"} navItem={d.navTab} />
                <Header data={d.Header} />
                <Services data={props.data} source={"ServicePage"} link={props.link} />
                <Companies data={d.Companies} link={d.link} service={d.title} />
                <Contact data={d.Contact} />
              </div>
            )
          }
        })
        :
        "Loading..."
      }
    </>
  )
}