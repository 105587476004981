import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {
  name: "",
  mobileNumber: "",
  email: "",
  location: "",
  businessSector: "",
  message: "",
};

const notify = () => {
  toast.success('Your message has been successfully sent!', {
    position: "top-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export const Contact = (props) => {

  let phone = "";
  let number = "";
  let emailAddress = "";

  props.data ? phone = props.data.phone : phone = "Loading";
  props.data ? number = props.data.mobile : number = "Loading";
  props.data ? emailAddress = props.data.email : emailAddress = "Loading";

  const [{ name, mobileNumber, email, location, businessSector, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(name, mobileNumber, email, location, businessSector, message);

    if (businessSector === "Real Estate Sector") {
      emailjs
        .sendForm("service_5gs2esc", "template_ja41rov", e.target, "ryATaCtwCACOTJFM7")
        .then(
          (result) => {
            console.log(result.text);
            clearState();
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
      notify();
    }
    else if (businessSector === "Construction and Manpower Sector") {
      emailjs
        .sendForm("service_5gs2esc", "template_u7q79wn", e.target, "ryATaCtwCACOTJFM7")
        .then(
          (result) => {
            console.log(result.text);
            clearState();
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();
      notify();
    }
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-7">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p> Please fill out the form below to send us an email and we will get back to you as soon as possible. </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Write your name."
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="number"
                        id="mobileNumber"
                        name="mobileNumber"
                        className="form-control"
                        placeholder="Write your mobile number."
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Write your email address."
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        name="location"
                        id="location"
                        className="form-control"
                        required
                        onChange={handleChange}
                      >
                        <option disabled selected value="">--Select Your Location--</option>
                        <option value="Abu Dhabi">Abu Dhabi</option>
                        <option value="Dubai">Dubai</option>
                        <option value="Sharjah">Sharjah</option>
                        <option value="Ajman">Ajman</option>
                        <option value="Umm Al Quwain">Umm Al Quwain</option>
                        <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                        <option value="Fujairah">Fujairah</option>
                        <option value="Out Side of UAE">Out Side of the UAE</option>
                      </select>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        name="businessSector"
                        id="businessSector"
                        className="form-control"
                        required
                        onChange={handleChange}
                      >
                        <option disabled selected value="">--Select The Business Sector--</option>
                        <option value="Real Estate Sector">Real Estate Sector</option>
                        <option value="Construction and Manpower Sector">Construction and Manpower Sector</option>
                      </select>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group ">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="5"
                    placeholder="Write your message."
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg"> Send Message </button>
              </form>
              <ToastContainer
                position="top-center"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-md-offset-1 contact-info">
              <div className="contact-item">
                <div className="section-title">
                  <h2>Contact Info</h2>
                </div>
                <span> <i className="fa fa-map-marker"></i> Address </span>
                <p className="contactValue"> {props.data ? props.data.address : "loading"}  </p>
              </div>
              <div className="contact-item">
                <span> <i className="fa fa-phone"></i> Phone </span>{" "}
                <p className="contactValue"> <a href={"tel:" + phone}>{phone}</a> </p>
              </div>
              <div className="contact-item">
                <span> <i className="fa fa-mobile"></i> Mobile </span>{" "}
                <p className="contactValue">
                  {props.data ? props.data.mobile.map((d, i) => {
                    if (props.data.mobile.length === i + 1) {
                      return (
                        <p><a key={`${d.name}-${i}`} href={"tel:" + d.number}>{d.number}</a><span className="supportArea"> {d.area}</span></p>
                      )
                    }
                    else {
                      return (
                        <p><a key={`${d.name}-${i}`} href={"tel:" + d.number}>{d.number}</a><span className="supportArea"> {d.area}</span>,</p>
                      )
                    }
                  }) : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <span> <i className="fa fa-envelope-o"></i> Email </span>{" "}
                <p className="contactValue"> <a className="contactEmail" href={"mailto:" + emailAddress}>{emailAddress}</a> </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};