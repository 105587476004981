//Essentials
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
// Components
import { Header } from "../components/header";
import { About } from "../components/about";
import { Services } from "../components/services";
import { Clients } from "../components/Clients";
import { Management } from "../components/Management";
import { Contact } from "../components/contact";
//Data
import Data from "../data/data.json";
import { Navigation } from "../components/navigation";

export const Home = () => {
  const { serviceLink } = useParams();

  const [data, setData] = useState({});
  useEffect(() => {
    setData(Data);
  }, []);

  return (
    <div className="homePage">
      <Navigation data={data.Services} source={"home"} />
      <Header data={data.Header} />
      <About data={data.About} />
      <Services data={data.Services} source={"home"} />
      <Clients data={data.Clients} />
      <Management data={data.Management} />
      <Contact data={data.Contact} />
    </div>
  );
};