import React from "react";

export const Clients = (props) => {
  return (
    <div id="clients">
      <div className="overlay">
        <div className="container">
          <div className="section-title text-center">
            <h2>Our Honorable Clients</h2>
            <p>We've completed total {props.data ? props.data.totalProject : "loading..."} well reputed  projects in UAE with {props.data ? props.data.totalWorkforce : "loading..."} skilled and semi skilled workers.</p>
          </div>
          <div className="row justify-content-md-center clientBox">
            {props.data ? props.data.clientList.map((d, i) => (
              <div key={`${d.img}-${i}`} className="col-xs-3 col-md-2">
                <div className="client">
                  <div className="client-image">
                    {" "}
                    <img src={d.img} alt="" />{" "}
                  </div>
                </div>
              </div>
            )) : "loading"}
          </div>
        </div>
      </div>
    </div>
  );
};