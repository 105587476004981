//Essentials
import React, { useEffect } from 'react'
//Components
import { useParams } from 'react-router-dom'
import { Header } from '../components/header';
import { About } from '../components/about';
import { Services } from '../components/services';
import { Contact } from '../components/contact';
import { Navigation } from '../components/navigation';

export const IndividualCompany = (props) => {
  const { companyLink } = useParams();

  return (
    <>
      <Navigation data={props.navData} source={"individualCompany"} />
      {props.data ?
        props.data.map((d, i) => {
          if (d.link === companyLink) {
            return (
              <div key={`${d.title}-${i}`} className="individualCompany">
                {" "}
                <Header data={d.Header} />
                <About data={d.About} />
                <Services data={d.Services} source={"individualCompany"} />
                <Contact data={d.Contact} />
              </div>
            )
          }
        })
        :
        "Loading..."
      }
    </>
  )
}