//Essentials
import React, { useState, useEffect } from "react";
import SmoothScroll from "smooth-scroll";
//router
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//pages
import { Home } from "./pages/home";
import { IndividualCompany } from "./pages/IndividualCompany";
//components
import { Navigation } from "./components/navigation";
import { Footer } from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
//Data
import Data from "./data/data.json";
//style
import "./App.css";
import { ServicePage } from "./pages/ServicePage";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1500,
  speedAsDuration: true,
});

const App = () => {

  const [data, setData] = useState({});
  useEffect(() => {
    setData(Data);
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/real-estate-services" element={<ServicePage data={data.Services} link={"real-estate-services"} />} />
          <Route path="/construction-and-manpower-services" element={<ServicePage data={data.Services} link={"construction-and-manpower-services"} />} />
          <Route path="/:companyLink" element={<IndividualCompany data={data.IndividualCompany} navData={data.Services} />} />
        </Routes>
        <Footer data={data.Footer} />
      </Router>
    </>
  );
};

export default App;